const requiredForms = document.querySelectorAll('form.cms-form-validate');

if (requiredForms) {
    requiredForms.forEach(form => {
        const requiredFields = form.querySelectorAll<HTMLFormElement>('[data-val-required]');
        if (requiredFields) {
            form.addEventListener('submit', e => {
                let valid = true;
                requiredFields.forEach(field => {
                    clearFieldValidationMessage(field);
                    if (field.value === '') {
                        valid = false;
                        displayFieldValidationMessage(field, undefined);
                    }
                });
                if (!valid) {
                    e.preventDefault();
                }
            });
        }
    });
}

const clearFieldValidationMessage = (field: HTMLFormElement) => {
    if (field) {
        const notice = field.parentElement.querySelector('[data-valmsg-for]');
        if (notice) {
            notice.classList.add('field-validation-valid');
            notice.innerHTML = '';
        }
    }
}
const displayFieldValidationMessage = (field: HTMLFormElement, message: string | undefined) => {
    if (field) {
        const notice = field.parentElement.querySelector('[data-valmsg-for]');
        if (notice) {
            notice.classList.remove('field-validation-valid');
            notice.innerHTML = message ?? field.dataset.valRequired;
        }
    }
}
// get selected file name
const uploadButtons = document.querySelectorAll<HTMLInputElement>('.upload-btn input[type="file"]');

if (uploadButtons) {
    uploadButtons.forEach(button => {
        const label = button.parentElement.parentElement.querySelector('span');
        button.addEventListener('change', (event) => {
            const files = (event.target as HTMLInputElement).files;
            const fileName = files[0].name;
            //console.log("file name: ", fileName);
            label.classList.remove('d-none');
            label.innerText = `Selected: ${fileName}`;
        });
    });
}