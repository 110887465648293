const animatedLogoRows = document.querySelectorAll<HTMLDivElement>('[data-logo-group]');
let activeIndex = 0;
let totalRows = 0;
const animationDuration = 1 * 1000; // seconds
const cycleDuration = 10 * 1000; // seconds


const fadeOutAnimatedLogos = (group: string) => {
    animatedLogoRows.forEach(img => {
        // get this items index
        const idx = img.dataset.logoGroup;
        if (idx === group) {
            // fade out (use CSS transition to delay)
            img.style.opacity = '0';
            // after css transition has finished hide the item
            setTimeout(() => { img.style.display = 'none'; }, animationDuration);
        }
    });
    // trigger next set now we have just hidden a set
    setTimeout(() => { activeIndex = activeIndex < (totalRows-1) ? (activeIndex + 1) : 0; fadeInAnimatedLogos(activeIndex + ""); }, animationDuration);
}
const fadeInAnimatedLogos = (group: string) => {
    animatedLogoRows.forEach(img => {
        // get this items index
        const idx = img.dataset.logoGroup;
        if (idx === group) {
            // set the item to be visible
            img.style.display = 'inline-block';
            // now wait and fade back in (use CSS transition to delay the fade)
            setTimeout(() => { img.style.opacity = '1'; }, 100);
        } else {
            img.style.opacity = '0';
        }
    });
    setTimeout(() => { fadeOutAnimatedLogos(group); }, cycleDuration);
}

// set initial state
if (animatedLogoRows && animatedLogoRows.length > 1) {
    totalRows = animatedLogoRows.length / 3;

    // hide all logos
    animatedLogoRows.forEach(img => {
        img.style.opacity = '0';
        img.style.display = 'none';
    });

    // fade in first set
    fadeInAnimatedLogos("0");
}