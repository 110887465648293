
const pageCardContainers = document.querySelectorAll<HTMLElement>(".grid-component-page-cards, .grid-component-numbered-page-cards");

const setEqualHeights = (elements: NodeListOf<HTMLElement>): void => {
    if (elements) {
        let maxHeight = 0;
        elements.forEach(element => {
            element.style.height = 'auto';
            const height = element.offsetHeight;
            if (height > maxHeight) {
                maxHeight = height;
            }
            
        });
        elements.forEach(element => {
            element.style.height = `${maxHeight}px`;
        });
    }
}

if (pageCardContainers) {
    pageCardContainers.forEach(container => {
        const cardTitles = container.querySelectorAll<HTMLElement>(".card-title");
        const cardBody = container.querySelectorAll<HTMLElement>(".card-text");

        if (cardTitles) {
            setEqualHeights(cardTitles);
            document.addEventListener('resize', () => { setEqualHeights(cardTitles); });
        }

        if (cardBody) {
            setEqualHeights(cardBody);
            document.addEventListener('resize', () => { setEqualHeights(cardBody); });
        }
    });
}

