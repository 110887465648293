import { error } from "console"

require('./components/you-tube-video-loader.js')
require('./components/external-link-handler.ts')
require('./components/equal-size-handler.ts')
require('./components/form-validation.ts')
require('./components/logo-fader.ts')
/*require('../node_modules/bootstrap/js/index.esm.js')*/
require('../sass/app.scss');

const clickLinks = document.querySelectorAll<HTMLDivElement>('[data-link-url]');
if (clickLinks) {
    clickLinks.forEach(link => {
        const url: string = link.dataset.linkUrl;
        if (!url) return;
        link.addEventListener('click', () => {
            window.location.href = url;
        });
    });
}

const faqs = document.querySelectorAll<HTMLDivElement>('.faq-list .faq');
if (faqs) {
    faqs.forEach(faq => {
        const btn: HTMLButtonElement = faq.querySelector('button');
        const expandBlock: HTMLDivElement = faq.querySelector('.faq-text');
        if (!btn) return;
        btn.addEventListener('click', () => {
            //console.log('clicks');
            expandBlock.classList.toggle('collapsed');
        });
    });
}

const autoSubmitForms = document.querySelectorAll<HTMLFormElement>('form.auto-submit');
if (autoSubmitForms) {
    autoSubmitForms.forEach(form => {
        const elements = form.querySelectorAll<HTMLInputElement>('input, select');
        if (!elements) return;
        elements.forEach(element => {
            element.addEventListener('change', () => {
                //console.log('changed!');
                form.submit();
            });
        });
    });
}

const mobileNavButton = document.getElementById('nav-toggle-icon');
const mobileNavMenu = document.getElementById('nav-toggle-menu');
const contentAreas = document.querySelectorAll('main, footer, .footer-message');
if (mobileNavButton) {
    mobileNavButton.addEventListener('click', (e) => {
        //console.clear(); console.log(e.target, 'Clicked');
        let btn = e.target as HTMLButtonElement;
        btn.classList.toggle('open');
        mobileNavMenu.classList.toggle('open');
        contentAreas.forEach(el => { el.classList.toggle('d-none'); });
        setTimeout(() => { mobileNavMenu.classList.add('hide'); }, 1000);
    });
}

const lpgQuoteForm = document.querySelector('.calculator-form');
if (lpgQuoteForm) {
    const quoteButton = lpgQuoteForm.querySelector('button');
    const quoteQuantity = lpgQuoteForm.querySelector<HTMLInputElement>('input[name="lpgQuoteQuantity"]');
    const quoteResult = document.getElementById('lpg-quote-result-total');
    const quoteResultPPL = document.getElementById('lpg-quote-result-ppl');
    const quoteDate = document.getElementById('lpg-quote-result-date');
    const quoteCalcLPGUrl = '/umbraco/surface/LPGQuoteSurface/get?quantity='
    if (quoteButton && quoteQuantity) {
        //console.log(quoteQuantity, "Qty");
        quoteButton.addEventListener('click', (e) => {
            const qty = parseInt(quoteQuantity.value);
            if (qty && qty > 0) {
                quoteButton.innerText = 'Please wait...';
                const response = fetch(`${quoteCalcLPGUrl}${qty}`).then(r => r.json()).then(quote => {
                    //console.log(quote.estimatedPrice);
                    quoteResult.innerHTML = new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: "GBP",
                    }).format(quote.estimatedPrice);
                    quoteResultPPL.innerHTML = quote.pricePerUnit;
                    quoteDate.innerText = quote.message;

                }).catch(error => {
                    alert('Sorry an error occurred.');
                }).finally(() => {
                    quoteButton.innerText = 'Calculate';
                });
            }
        });
        quoteQuantity.addEventListener('keyup', (e) => {

            if (e.key === 'Enter') {
                quoteButton.click();
            }
        });
    }
}
